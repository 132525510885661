<template>
  <div>
    <div class="flex" style="align-items: center">
      <div style="
          border-bottom: 1px solid #e6e6e6;
          font-size: 24px;
          color: #fb1010;
          font-weight: bold;
          padding: 10px;
        ">
        入企业服务月度反馈表

        <div class="flex" style="
            width: 142px;
            height: 4px;
            background: rgb(251, 16, 16);
            border-radius: 2px;
            position: relative;
            top: 10px;
            left: 50px;
          "></div>
      </div>
      <div style="
          width: 122px;
          height: 40px;
          line-height: 40px;
          background: #ff6618;
          border-radius: 6px;
          color: #fff;
          text-align: center;
          margin-left: 30px;
        " @click="postcurrency()">
        添加月报
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="ID" label="ID" min-width="100"> </el-table-column>
      <el-table-column prop="username" label="填报人" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="enterprise_num" label="服务企业数量" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="num" label="入企次数" min-width="180" align="center">
      </el-table-column>

      <el-table-column prop="dock_num" label="对接需求数量" min-width="180" align="center">
      </el-table-column>
      <el-table-column label="入企服务具体情况" min-width="180" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="lookmessage(scope.row)">查看月报详情</el-button>
        </template>
      </el-table-column>

      <el-table-column prop="month" label="报送月份" min-width="180" align="center">
      </el-table-column>

      <el-table-column prop="create_time" label="报送时间" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="operation" label="操作" min-width="180" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="upfeedback(scope.row)">修改月报</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog title="查看月报详情" :visible.sync="looklogin" width="600px" center>
      <div>
        <div class="flex jus_content marb_20">
          <div>服务典型案例</div>
          <div>{{ lookbox.cases }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>入企服务过程中遇到的困难</div>
          <div>{{ lookbox.difficulty }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>服务企业数量</div>
          <div>{{ lookbox.enterprise_num }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>入企次数</div>
          <div>{{ lookbox.num }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>对接需求数量</div>
          <div>{{ lookbox.dock_num }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>报送月份</div>
          <div>{{ lookbox.month }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>典型案例</div>
          <div>{{ lookbox.cases }}</div>
        </div>
        <div class="flex jus_content marb_20">
          <div>意见建议</div>
          <div>{{ lookbox.cases }}</div>
        </div>
        <div v-for="(item, index) in lookbox.report">
          <div class="flex jus_content marb_20">
            <div>服务类别</div>
            <div>{{ item.cate_name }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>是否需要联盟协调</div>
            <div>{{ item.is_coordinate == 1 ?'是' : '否' }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>服务典型案例介绍</div>
            <div>{{ item.content }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>服务需求</div>
            <div>{{ item.demand }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>对接形式</div>
            <div>{{ item.dock }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>对接形式</div>
            <div>{{ item.dock }}</div>
          </div>
          <div class="flex jus_content marb_20">
            <div>企业名称</div>
            <div>{{ item.name }}</div>
          </div>
        </div>

      </div>
    </el-dialog>




    <el-pagination v-if="tableData.length != 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
    <!-- </el-tab-pane> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      looklogin: false,
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      allTableData: [],
      tableData: [],
      total: "",
      lookbox: {}
      // activeName: "first",
    };
  },
  created() {
    this.getmonthlylist();
  },
  methods: {
    lookmessage(item) {
      let data = {
        token: localStorage.eleToken,
        id: item.ID
      };
      this.$get("/monthly_report_lst_d", data).then((res) => {
        console.log(res, 'reslooklogin')
        this.looklogin = true
        this.lookbox = res.result
      })

    },
    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.goaddfeedback();
        } else if (res.status === 2) {
          // setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            // location.href = "http://sso.smeqh.cn:9000/realname/index";
            this.$message({
              type: "info",
              message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
            });
          // }, 2000);
        }
      });
    },

    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("commissioner_currency", data).then((res) => {
        console.log(res, "res");
        if (res.result.audit_status === 2) {
          this.goaddfeedback();
        } else if (
          res.result.audit_status === 0 ||
          res.result.audit_status === 3
        ) {
          this.authentication();
        } else if (res.result.audit_status === 1) {
          this.$message({
            type: "info",
            message: "审核中",
          });
        }
      });
    },
    upfeedback(item) {
      console.log(item,'item')
      this.$router.push({ path: "/addfeedback", query: { id: item.ID } });
      // this.$router.push({ path: "/Addnextmonthly", query: { ID: item.ID } });
    },
    goaddfeedback() {
      this.$router.push("/addfeedback");
    },
    getmonthlylist() {
      let data = {
        token: localStorage.eleToken,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$post("monthly_report_lst", data).then((res) => {
        console.log(res, "res");
        this.total = res.count;
        this.allTableData = res.result;
        this.setPaginations();
      });
    },

    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      this.console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

    //重置分页
    setPaginations() {
      //初始化
      this.paginations.total = this.allTableData.length;
      this.paginations.page_index = 1;
      this.paginations.page_size = 10;
      //设置默认的分页数据
      this.tableData = this.allTableData.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}
</style>
